var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"boxLeft"},[_c('div',[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("talentZone")))]),_c('span',{staticClass:"message",class:{ messEn: _vm.LOCALE == 'en' }},[_vm._v(_vm._s(_vm.$t("checkRecruitment")))])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.leftLoading),expression:"leftLoading"}],staticClass:"list"},[_vm._l((_vm.leftList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item_left"},[_c('img',{staticClass:"img",attrs:{"src":_vm.icons[index],"alt":_vm.$t('siffa')}}),_c('div',{staticClass:"adress pointer"},[_c('a',{attrs:{"href":'/positionDetail?parameter=' +
                _vm._encode({
                  id: item.id,
                })}},[_vm._v(" "+_vm._s(item.name)+" ")])])]),_c('span',{staticClass:"item_right date",class:{ dateEn: _vm.LOCALE=='en' }},[_vm._v(_vm._s(_vm._f("priorFormat")(item.city_name_en,item.city_name_zh, _vm.LOCALE))+" | "+_vm._s(_vm._f("experienceFormat")(item.experience,_vm.LOCALE))+" "),(_vm.LOCALE == 'zh')?_c('span',[_vm._v(" |"+_vm._s(_vm._f("levelTypeFormat")(item.level_require,_vm.LOCALE))+" ")]):_vm._e()])])}),(!_vm.leftLoading && _vm.leftList.length === 0)?_c('noDataImg'):_vm._e()],2)]),_c('div',{staticClass:"boxRight clearfix"},[_c('div',{staticClass:"company fl"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("memberNews")))]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.rightLoading),expression:"rightLoading"}],staticClass:"list"},[_vm._l((_vm.rightList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"city"},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(item.city_name_en,item.city_name_zh, _vm.LOCALE))+" ")]),_c('div',{staticClass:"name"},[_c('span',{staticClass:"pointer"},[_c('a',{attrs:{"href":'/companyDetail?parameter=' +
                  _vm._encode({
                    company_id: item.company_id,
                  })}},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(item.company_name_en,item.company_name_zh, _vm.LOCALE))+" ")])])])])}),(!_vm.rightLoading && _vm.rightList.length === 0)?_c('noDataImg'):_vm._e()],2)]),_c('div',{staticClass:"img-wrap fr"},[(_vm.advertising3)?_c('div',{staticClass:"bannerInner",class:{
          pointer:
            _vm.advertising3.link_url &&
            _vm.advertising3.extend &&
            !_vm.advertising3.extend.is_show,
        },style:({ 'background-image': 'url(' + _vm.advertising3.img_url + ')' }),on:{"click":function($event){return _vm.handleDivLink(_vm.advertising3)}}},[(
            _vm.advertising3.link_url &&
            _vm.advertising3.extend &&
            !_vm.advertising3.extend.is_show
          )?_c('div',{staticClass:"bannerHerf"},[(
              _vm.advertising3.link_url &&
              _vm.advertising3.link_url.indexOf('http') == '0'
            )?_c('a',{attrs:{"href":_vm.advertising3.link_url,"target":"_blank","rel":"nofollow"}}):_c('a',{attrs:{"href":_vm.advertising3.link_url,"rel":"nofollow"}})]):_vm._e(),(_vm.advertising3.extend && _vm.advertising3.extend.is_show)?_c('div',{staticClass:"bannerBtn",class:'btnStyle' + _vm.advertising3.extend.buttonStyle,style:({
            bottom: `${_vm.advertising3.extend.bottom}%`,
            right: `${_vm.advertising3.extend.right}%`,
            background: _vm.advertising3.extend.backgroundColor,
            borderColor: _vm.advertising3.extend.borderColor,
          })},[(
              _vm.advertising3.link_url &&
              _vm.advertising3.link_url.indexOf('http') == '0'
            )?_c('a',{style:({
              fontSize: `0.${_vm.advertising3.extend.fontSize}rem`,
              color: _vm.advertising3.extend.color,
            }),attrs:{"href":_vm.advertising3.link_url,"target":"_blank","rel":"nofollow"}},[_vm._v(" "+_vm._s(_vm.advertising3.extend.content))]):_c('a',{style:({
              fontSize: `0.${_vm.advertising3.extend.fontSize}rem`,
              color: _vm.advertising3.extend.color,
            }),attrs:{"href":_vm.advertising3.link_url,"rel":"nofollow"}},[_vm._v(" "+_vm._s(_vm.advertising3.extend.content))])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }