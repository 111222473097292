<template>
    <div class="box">
        <div class="boxLeft">
            <div class="top">
                <span class="title">{{ $t("eventNotification") }}</span>
                <div class="pointer">
                    <a href="/activity?content_type=1">
                        <span class="more">{{ $t("more") }}</span>
                        <i
                            class="el-icon-arrow-right"
                            style="color: #ffffff"
                        ></i>
                    </a>
                </div>
            </div>
            <div class="bottom" v-loading="leftLoading">
                <div
                    class="font"
                    v-for="(item, index) in leftList"
                    :key="index"
                >
                    <a
                        :href="'/activityDetail?id=' + item.id"
                        target="_blank"
                        class="pointer"
                    >
                        {{
                            item.name_en | priorFormat(item.name_zh, LOCALE)
                        }}</a
                    >
                </div>
                <noDataImg
                    v-if="!leftLoading && leftList.length === 0"
                ></noDataImg>
            </div>
        </div>
        <div class="boxCenter">
            <div class="top">
                <el-tabs v-model="activeName" @tab-click="handleClickCenterTab">
                    <el-tab-pane
                        v-for="(item, index) in tags"
                        :name="item.name"
                        :key="index"
                    >
                        <span slot="label" class="titleBox">
                            <img
                                :src="
                                    activeName === item.name
                                        ? item.imgActive
                                        : item.img
                                "
                                :alt="$t('siffa')"
                            />
                            <span class="title">{{ item.label }}</span>
                        </span>
                    </el-tab-pane>
                </el-tabs>
                <div class="moreBox pointer">
                    <!-- @click="handleCerterMore" -->
                    <a :href="'/activitys?content_type=' + activeName * 1">
                        <span class="more">{{ $t("more") }}</span>
                        <i class="el-icon-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="bottom" v-loading="centerLoading">
                <div v-for="(item, index) in centerList" :key="index">
                    <div v-if="index === 0" class="titleBox">
                        <el-image
                            :src="item.img_url ? item.img_url : defaultImg"
                            :alt="$t('siffa')"
                            fit="scale-down"
                            class="img pointer"
                            @click="goDetail(item)"
                        >
                            <!-- <a
                :href="'/detail?id=' + item.id"
                target="_blank"
                class="pointer"
              ></a> -->
                        </el-image>
                        <div>
                            <div class="samllTitle">
                                <span
                                    @click="goDetail(item)"
                                    class="pointer"
                                    v-if="item.id == 45671"
                                    >{{ item.title | fullTextFormat(25) }}</span
                                >
                                <a
                                    v-else
                                    :href="'/detail?id=' + item.id"
                                    target="_blank"
                                    class="pointer"
                                >
                                    {{ item.title | fullTextFormat(25) }}
                                </a>
                            </div>
                            <!--<div class="smallTime tc">
                发布时间：{{ item.created_time | secondFormat("LL") }} 
              </div>-->
                            <div class="smallContent">
                                <span
                                    @click="goDetail(item)"
                                    class="pointer"
                                    v-if="item.id == 45671"
                                    >{{ item.title | fullTextFormat(25) }}</span
                                >
                                <a
                                    v-else
                                    :href="'/detail?id=' + item.id"
                                    target="_blank"
                                    class="pointer"
                                >
                                    {{ item.content | fullTextFormat(80) }}
                                </a>
                                <!-- <span @click="goDetail(item)" class="pointer">{{
                  item.content | fullTextFormat(80)
                }}</span> -->
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="titles">
                            <div class="titleItem">
                                <div class="titleText pointer">
                                    <a
                                        :href="'/detail?id=' + item.id"
                                        target="_blank"
                                        class="pointer"
                                    >
                                        {{ item.title | textFormat }}
                                    </a>
                                </div>
                                <div class="titleDate pointer">
                                    <a
                                        :href="'/detail?id=' + item.id"
                                        target="_blank"
                                        class="pointer"
                                    >
                                        {{
                                            item.created_time
                                                | secondFormat("LL")
                                        }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <noDataImg
                    v-if="!centerLoading && centerList.length === 0"
                ></noDataImg>
            </div>
        </div>
        <div class="boxRight">
            <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/iconTop.png"
                class="img"
                alt=""
                srcset=""
            />
            <div class="iconList" id="point">
                <div
                    class="iconItem"
                    v-for="(item, index) in rightImgs"
                    :key="index"
                >
                    <img
                        @click="handleRightImg(item)"
                        :src="item.img"
                        alt=""
                        srcset=""
                    />
                    <p>{{ item.text }}</p>
                </div>
            </div>
            <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/iconBot.png"
                class="img"
                alt=""
                srcset=""
            />
            <!-- <img
        v-for="(item, index) in rightImgs"
        :src="item.img_en | priorFormat(item.img_zh, LOCALE)"
        :key="index"
        :alt="$t('siffa')"
        class="img pointer"
        @click="handleRightImg(item)"
      /> -->
            <!-- <div class="moreBox">
        <span class="more" @click="goDownloadSection">最新资料</span>
        <span @click="goDownloadSection" class="pointer">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div class="downBox">
        <div v-for="(item) in rightList" :key="item.id" class="tieleItem">
          <span class="pointer" @click="handleRightTitle(item)">{{ item.name }}</span>
        </div>
      </div> -->
        </div>
        <div class="rightNav" v-show="show">
            <div class="iconList">
                <div
                    class="iconItem"
                    v-for="(item, index) in rightImgs"
                    v-if="index < 2"
                    :key="index"
                >
                    <img
                        @click="handleRightImg(item)"
                        :src="item.img"
                        alt=""
                        srcset=""
                    />
                    <p>{{ item.text }}</p>
                </div>
            </div>
            <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/rightBg.png"
                alt=""
                srcset=""
            />
            <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/iconBot.png"
                class="link"
                alt=""
            />
        </div>
        <div class="rightIcon">
            <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/iocn_catalogue.png"
                alt=""
                @click="goToMemorabilia"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "activity",
    data() {
        return {
            activeName: "2",
            rightImgs: [
                {
                    img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/icon1.png",
                    path: "/honestCompany",
                    text: "诚信公约名单",
                },
                {
                    img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/icon2.png",
                    path: "/creditAssessment",
                    text: "信用评估公示",
                },
                {
                    img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/icon3.png",
                    path: "/memberShip",
                    text: "申请入会",
                },
                {
                    img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/icon4.png",
                    path: "/downloadSection",
                    text: "下载专区",
                },
            ],
            tags: [
                {
                    label: this.$t("associationNews"),
                    name: "2",
                    img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/dynamic.png",
                    imgActive:
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/dynamicActive.png",
                },
                {
                    label: this.$t("industryInfo"),
                    name: "3",
                    img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/association.png",
                    imgActive:
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/associationActive.png",
                },
                {
                    label: this.$t("policiesRegu"),
                    name: "4",
                    img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/policy.png",
                    imgActive:
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/policyActive.png",
                },
            ],
            leftList: [],
            leftLoading: false,
            centerList: [],
            centerLoading: false,
            rightList: [],
            rightLoading: false,
            show: false,
        };
    },
    mounted() {
        let top = document.getElementById("point").offsetTop + 80;
        let _this = this;
        window.onscroll = function () {
            //为了保证兼容性，这里取两个值，哪个有值取哪一个
            //scrollTop就是触发滚轮事件时滚轮的高度
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            console.log(top);
            if (scrollTop >= top) {
                _this.show = true;
            } else {
                _this.show = false;
            }
        };
    },
    computed: {
        defaultImg() {
            let img = "";
            switch (this.activeName) {
                case "2":
                    let zhImg =
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationActivities.png";
                    let enImg =
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/AssociationActivitiesEn.png";
                    img = this.LOCALE == "en" ? enImg : zhImg;
                    break;
                case "3":
                    let zhImg1 =
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/InformationIndustry.png";
                    let enImg1 =
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/InformationIndustrEn.png";
                    img = this.LOCALE == "en" ? enImg1 : zhImg1;
                    break;
                case "4":
                    let zhImg2 =
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/Policies_regulations.png";
                    let enImg2 =
                        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/Policies_regulationsEn.png";
                    img = this.LOCALE == "en" ? enImg2 : zhImg2;
                    break;
            }
            return img;
        },
    },
    created() {
        this.getLeftList();
        this.getCerterList();
        this.getRightList();
    },
    methods: {
        async getLeftList() {
            let params = {
                start: 0,
                limit: 6,
                is_show: 1,
                is_top: 1,
                source: this.PJSource,
                user_id: this.USER_INFO.id,
                company_id: this.USER_INFO.company_id,
            };
            this.leftLoading = true;
            let res = await this.$store.dispatch(
                "API_index/association_event",
                params
            );
            console.log(res);
            this.leftLoading = false;
            if (res.success) {
                this.leftList = res.data;
            }
        },
        async getCerterList() {
            let params = {
                content_type: this.activeName,
                source: this.PJSource,
                nopage: 0,
                limit: 5,
                start: 0,
                language: this.LOCALE == "en" ? 1 : 2,
            };
            this.centerLoading = true;
            let res = await this.$store.dispatch(
                "API_index/getStaticContent",
                params
            );
            this.centerLoading = false;
            if (res.success) {
                console.log(res.data);
                this.centerList = res.data;
            }
        },
        // handleLeftMore() {
        //   this.$router.push({
        //     path: "/activity",
        //     query: { content_type: 1 },
        //   });
        // },
        goDetail(item) {
            this.$router.push({
                path: "/whereDelivery",
                query: { id: item.id },
            });
        },
        goToMemorabilia() {
            this.$router.push({
                path: "/survey/memorabilia",
            });
        },
        handleClickCenterTab() {
            this.getCerterList();
        },
        handleCerterMore() {
            this.$router.push({
                path: "/activitys",
                query: {
                    content_type: this.activeName * 1,
                },
            });
        },
        handleRightImg(val) {
            let path = val.path;
            if (val.path === "/memberShip" && !this.IS_LOGIN) {
                return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
            }
            this.$router.push(path);
        },
        handleRightTitle(item) {
            window.open(item.url);
        },
        goDownloadSection() {
            this.$router.push("/downloadSection");
        },
        async getRightList() {
            this.rightLoading = true;
            let res = await this.$store.dispatch("API_index/downloadList", {
                source: this.PJSource,
                start: 0,
                nopage: 0,
                is_show: 1,
                language: this.LOCALE == "en" ? 1 : 2,
                limit: 2,
            });
            this.rightLoading = false;
            if (res.success) {
                this.rightList = res.data;
            }
        },
    },
};
</script>

<style scoped lang="less">
.box {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    height: 272px;
    .boxLeft {
        width: 302px;
        height: 100%;
        .top {
            width: 302px;
            height: 46px;
            display: flex;
            align-items: center;
            padding: 0 8px 0 20px;
            justify-content: space-between;
            background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/indexActivityLeft.png");
            background-size: 100% 100%;
            .title {
                font-size: 18px;
                font-weight: 800;
                color: #ffffff;
            }
            .more {
                font-size: 14px;
                color: #ffffff;
            }
        }
        .bottom {
            height: 226px;
            background: #f6faff;
            padding: 20px 28px 26px 20px;
            position: relative;
            .font {
                color: #1f292e;
                width: 255px;
                margin-bottom: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .boxCenter {
        width: 605px;
        height: 100%;
        .top {
            padding-top: 4px;
            width: 100%;
            height: 46px;
            overflow: hidden;
            position: relative;
            background-color: #fcfcfc;
            /deep/ .el-tabs__nav-wrap::after {
                background-color: #fcfcfc;
                border-bottom: 2px;
            }
            /deep/ .el-tabs__item {
                font-size: 18px;
                font-weight: 800;
                color: #1f292e;
            }
            /deep/ .el-tabs__item {
                font-size: 18px;
                font-weight: 800;
                color: #909399;
            }
            /deep/ .el-tabs__item.is-active {
                color: #1f292e;
            }
            /deep/ .el-tabs__active-bar {
                background-color: #ff5155;
            }
            .moreBox {
                position: absolute;
                right: 0;
                top: 16px;
                .more {
                    font-size: 14px;
                    color: #909399;
                }
            }
            .titleBox {
                padding: 0 8px;
                .titleIcon {
                    color: #ff5155;
                }
                .title {
                    margin-left: 14px;
                }
            }
        }
        .bottom {
            height: 226px;
            background: #fcfcfc;
            padding: 16px 20px 0px;
            position: relative;
            .titleBox {
                display: flex;
                .img {
                    width: 127px;
                    height: 96px;
                    background: yellowgreen;
                    margin-right: 20px;
                }
                .samllTitle {
                    color: #1f292e;
                    font-size: 16px;
                    font-weight: bold;
                    width: 418px;

                    margin-bottom: 4px;
                }
                .smallTime {
                    color: #909399;
                }
                .smallContent {
                    width: 418px;
                    color: #1f292e;
                    font-weight: 400;
                    word-wrap: break-word;
                    margin-top: 10px;
                }
            }
            .titles {
                padding-top: 2px;
                .titleItem {
                    display: flex;
                    margin-top: 6px;
                    justify-content: space-between;
                    .titleText {
                        color: #909399;
                        max-width: 380px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;
                    }
                    .titleDate {
                        color: #909399;
                    }
                }
            }
        }
    }
    .boxRight {
        width: 245px;
        height: 272px;
        .img {
            width: 100%;
        }

        //   width: 245px;
        //   height: 58px;
        //   margin-bottom: 10px;
        // }
        // .moreBox {
        //   display: flex;
        //   justify-content: space-between;
        //   .more {
        //     color: #7d8695;
        //     font-size: 12px;
        //     cursor: pointer;
        //   }
        // }
        // .downBox {
        //   position: relative;
        //   .tieleItem {
        //     color: #7d8695;
        //     font-size: 12px;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     width: 240px;
        //     margin-top: 4px;
        //   }
        // }
    }
    .pointer {
        cursor: pointer;
    }
}
.iconList {
    background-color: #fcfcfc;
    width: 243px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    border-left: 1px solid #86aee6;
    border-right: 1px solid #86aee6;
    box-sizing: border-box;
    .iconItem {
        width: 120px;
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
        p {
            margin-top: 5px;
            font-size: 14px;
        }
    }
}
// .img {
.rightNav {
    width: 323px;
    height: 313px;
    position: fixed;
    right: 0;
    bottom: 100px;
    z-index: 999;
    .link {
        width: 233px;
        height: 56px;
        position: absolute;
        left: 42px;
        bottom: 13px;
    }
    .iconList {
        border: none;
        position: absolute;
        left: 42px;
        bottom: 80px;
    }
}
.rightIcon {
    width: 135px;
    height: 125px;
    position: fixed;
    right: 0;
    bottom: 450px;
    z-index: 999;
    img {
        width: 100%;
        height: 100%;
    }
}
</style>