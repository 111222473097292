<template>
  <div class="container">
    <!-- 轮播图组件 -->
    <!-- <carouselBanner paddingTop="24%" :bannerType="1" /> -->
    <div class="index_content content_area">
      <baseShippingDate
        style="background: #f5f5f5"
        :useType="2"
        @handleSearch="handleShipSearch"
        :searchTopBg="'#f5f5f5'"
        :searchBtn="'background:linear-gradient(270deg, #E7C493 0%, #F7E9BD 98%); border: none; font-size: 20px; color: #9D7024; font-weight: 400;'"
      ></baseShippingDate>
      <activity />
      <div id="point"></div>
      <!-- 广告位1 -->
      <!-- <advertising1 :advertising1="advertising1" v-if="advertising1" /> -->
      <advertising1 style="margin-top: 40px" />
      <schoolCooperation :advertising3="advertising3" />
      <div v-if="advertising2" class="mobilizationBanner">
        <div class="mobilizationBannerImg">
          <div
            class="bannerInner"
            :class="{
              pointer:
                advertising2.link_url &&
                advertising2.extend &&
                !advertising2.extend.is_show,
            }"
            :style="{ 'background-image': 'url(' + advertising2.img_url + ')' }"
            @click="handleDivLink(advertising2)"
          >
            <div
              v-if="advertising2.extend && advertising2.extend.is_show"
              class="bannerBtn"
              :class="'btnStyle' + advertising2.extend.buttonStyle"
              :style="{
                bottom: `${advertising2.extend.bottom}%`,
                right: `${advertising2.extend.right}%`,
                background: advertising2.extend.backgroundColor,
                borderColor: advertising2.extend.borderColor,
              }"
              @click="handleLink(advertising2.link_url)"
            >
              <el-button
                type="text"
                :style="{
                  fontSize: `0.${advertising2.extend.fontSize}rem`,
                  color: advertising2.extend.color,
                }"
                >{{ advertising2.extend.content }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <education />
      <cooperation />

    </div>
    <!-- <div class="cooperation">
      <div class="content_area">
      </div>
    </div> -->
  </div>
</template>

<script>
import loginDialog from "~sif/components/common/loginDialog";
import carouselBanner from "@/baseComponents/carouselBanner";
import activity from "~sif/components/index/home/activity";
import schoolCooperation from "~sif/components/index/home/schoolCooperation";
import education from "~sif/components/index/home/education";
import cooperation from "~sif/components/index/home/cooperation";
import baseShippingDate from "~/baseComponents/baseShippingDate/components/search";
import advertising1 from "~sif/components/index/home/advertising1";
export default {
  metaInfo() {
    return {
      title: this.$t("associationTips"),
      meta: [
        {
          name: "keyWords",
          content:
            "上海市国际货运代理行业协会,货代协会,货运代理 协会,上海市货代协会官网,货代协会总部,SIFFA",
        },
        {
          name: "description",
          content:
            "上海市国际货运代理行业协会（SIFFA）于 1992 年 9 月成立,总部位于上海,是我国最早成立的省市级国际货运代理行业协会,旨在协调行 业内外各种关系,促进上海国际货运代理行业的健康发展,目前上海市国际货运代理行业 协会现有会员单位 420 家",
        },
      ],
    };
  },
  components: {
    loginDialog,
    carouselBanner,
    activity,
    schoolCooperation,
    education,
    cooperation,
    baseShippingDate,
    advertising1,
  },
  data() {
    return {
      // advertising1: null,
      advertising2: null,
      advertising3: null,
      show:false
    };
  },
  mounted() {
  
  },
  computed: {},
  created() {
    this.getAdvertisingList();
  },
  methods: {
    async getAdvertisingList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "1,2,3",
      };
      let res = await this.$store.dispatch(
        "API_index/getAdvertisingList",
        params
      );
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
          // if (item.serial_no == 1) {
          //   this.advertising1 = item
          // }
          if (item.serial_no == 2) {
            this.advertising2 = item;
          }
          if (item.serial_no == 3) {
            this.advertising3 = item;
          }
        });
      }
    },
    handleLink(link) {
      if (!link) return;
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url);
      }
    },
    handleShipSearch(val, val2) {
      if (val.date) val.date = this.$moment(val.date).unix();
      this.$router.push({
        path: "/baseShippingDate",
        query: {
          polCode: val.polCode,
          podCode: val.podCode,
          date: val.date,
          polName: val2.polName,
          podName: val2.podName,
        },
      });
    },
    advertisingData(e) {
      this.advertising1 = e;
      console.log(this.advertising1);
    },
  },
};
</script>
<style scoped lang="less">
.container {
  background: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/home_bg.png');
  background-size: cover;
  overflow: hidden;
  .index_content{
    margin-top: 470px;
  }
  .mobilizationBanner {
    margin-top: 36px;
    width: 100%;
    padding-top: 10.83%;
    position: relative;
    .mobilizationBannerImg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      .bannerInner {
        height: 100%;
        background-size: 100% 100%;
        position: relative;
        .bannerBtn {
          position: absolute;
          padding: 0px 20px;
          border: 1px solid;
          border-radius: 4px;
          transform: translate(50%, 50%);
          &.btnStyle1 {
            border-radius: 0px;
          }
          &.btnStyle2 {
            border-radius: 4px;
          }
          &.btnStyle3 {
            border-radius: 999px;
          }
        }
      }
    }
  }
  .cooperation {
    margin-top: 36px;
    width: 100%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/footBanner.png");
    height: 299px;
    background-size: 100% 100%;
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
