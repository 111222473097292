<template>
  <div>
    <div v-if="advertising1" class="creditBanner">
      <div class="creditBannerImg">
        <div
          class="bannerInner"
          :class="{
            pointer:
              advertising1.link_url &&
              advertising1.extend &&
              !advertising1.extend.is_show,
          }"
          :style="{ 'background-image': 'url(' + advertising1.img_url + ')' }"
        >
          <!--@click="handleDivLink(advertising1)"  -->
          <div
            class="bannerHerf"
            v-if="
              advertising1.link_url &&
              advertising1.extend &&
              !advertising1.extend.is_show
            "
          >
            <a
              :href="advertising1.link_url"
              target="_blank"
              rel="nofollow"
              v-if="
                advertising1.link_url &&
                advertising1.link_url.indexOf('http') == '0'
              "
            ></a>
            <a :href="advertising1.link_url" rel="nofollow" v-else></a>
          </div>
          <div
            v-if="advertising1.extend && advertising1.extend.is_show"
            class="bannerBtn"
            :class="'btnStyle' + advertising1.extend.buttonStyle"
            :style="{
              bottom: `${advertising1.extend.bottom}%`,
              right: `${advertising1.extend.right}%`,
              background: advertising1.extend.backgroundColor,
              borderColor: advertising1.extend.borderColor,
            }"
          >
            <!-- @click="handleLink(advertising1.link_url)" -->
            <a
              :style="{
                fontSize: `0.${advertising1.extend.fontSize}rem`,
                color: advertising1.extend.color,
              }"
              :href="advertising1.link_url"
              target="_blank"
              rel="nofollow"
              v-if="
                advertising1.link_url &&
                advertising1.link_url.indexOf('http') == '0'
              "
            >
              {{ advertising1.extend.content }}</a
            >
            <a
              :href="advertising1.link_url"
              rel="nofollow"
              :style="{
                fontSize: `0.${advertising1.extend.fontSize}rem`,
                color: advertising1.extend.color,
              }"
              v-else
            >
              {{ advertising1.extend.content }}</a
            >
            <!-- <el-button
              type="text"
              :style="{
                fontSize: `0.${advertising1.extend.fontSize}rem`,
                color: advertising1.extend.color,
              }"
              >{{ advertising1.extend.content }}</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   advertising1: {
  //     type: Object,
  //     default: null,
  //   },
  // },
  data() {
    return {
      advertising1: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let params = {
        language: this.LOCALE == "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "1",
      };
      let res = await this.$store.dispatch(
        "API_index/getAdvertisingList",
        params
      );
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
          if (item.serial_no == 1) {
            this.advertising1 = item;
          }
        });
      }
    },
    handleLink(link) {
      if (!link) return;
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        // this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.creditBanner {
  margin-top: 24px;
  width: 100%;
  padding-top: 10%;
  position: relative;
  .creditBannerImg {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    .bannerInner {
      height: 100%;
      background-size: 100% 100%;
      position: relative;
      .bannerHerf {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        a {
          display: block;
          height: 100%;
          width: 100%;
        }
      }
      .bannerBtn {
        position: absolute;

        padding: 0px 20px;
        border: 1px solid;
        border-radius: 4px;
        transform: translate(50%, 50%);
        &.btnStyle1 {
          border-radius: 0px;
        }
        &.btnStyle2 {
          border-radius: 4px;
        }
        &.btnStyle3 {
          border-radius: 999px;
        }
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>
