<template>
    <div class="box">
        <div class="left">
            <div class="title">{{ $t("presidentUnit") }}</div>
            <div class="list">
                <div
                    v-for="(item, index) in dataList"
                    :key="index"
                    class="item"
                >
                    <el-image
                        :src="item.img_url"
                        :alt="$t('siffa')"
                        fit="scale-down"
                        class="item"
                    >
                    </el-image>
                    <a
                        :href="item.link_url"
                        target="_blank"
                        class="itemHerf"
                    ></a>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="title">{{ $t("vicePresidentUnit") }}</div>
            <div class="list">
                <div class="imgSilder" style="width: 780px">
                    <el-carousel
                        :initial-index="value1"
                        :arrow="imgList.length > 1 ? 'always' : 'never'"
                        dots="none"
                        height="60"
                        indicator-position="none"
                        trigger="click"
                        :loop="true"
                    >
                        <el-carousel-item
                            v-for="(item, index) in imgList"
                            :key="index"
                            :name="index + ''"
                        >
                            <div class="demo-carousel clearfix">
                                <div
                                    class="fl img-wrap"
                                    v-for="(ele, index) in item"
                                    :key="index"
                                >
                                    <el-image
                                        class="item-img"
                                        :src="ele.img_url"
                                        fit="scale-down"
                                        @click="gotoWebsite(ele)"
                                        style="cursor: pointer"
                                        :alt="$t('siffa')"
                                    >
                                    </el-image>
                                    <a
                                        :href="ele.link_url"
                                        target="_blank"
                                        class="itemHerf"
                                    ></a>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <el-dialog
                        fullscreen
                        :visible.sync="showDialog"
                        class="imgPreivewDialog"
                        :show-close="false"
                    >
                        <el-image
                            class="diaLogImage"
                            style="width: 0px; height: 0px"
                            :src="previewList[0]"
                            :preview-src-list="previewList"
                            @closePreview="showDialog = false"
                            :alt="$t('siffa')"
                            ref="diaLogImage"
                        ></el-image>
                    </el-dialog>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="title">{{ $t("links") }}</div>
            <div class="list">
                <ul class="link_box">
                    <li
                        v-for="(item, i) in Links_arr"
                        :key="i"
                        @click="link_click(item)"
                        class="clearfix"
                    >
                        <a :href="item.href" target="_blank" rel="nofollow">
                            <img
                                :src="item.url"
                                alt=""
                                fit="contain"
                                :alt="$t('siffa')"
                            />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { splitArr } from "~/baseUtils";
export default {
    name: "cooperation",
    data() {
        return {
            dataList: [
                // {
                //   url:
                //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/1.png",
                //   website: "http://www.sinotrans.com/",
                // },
            ],
            dataListWrap: [
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/sgwl.jpg",
                //   website:"https://sgwl.portshanghai.com.cn/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/ciewtrans.jpg",
                //   website:"http://www.viewtrans.com/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/orientil.png",
                //   website:"http://www.orientil.com/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/ctsfr.png",
                //   website:"https://www.ctsfreight.com/cn/home/index.aspx"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/syslist.bmp",
                //   website:"http://www.syntrans.com/html/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/jhj.png",
                //   website:"http://www.jhj.com.cn/JHJPortal/main/index.aspx"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/goto.bmp",
                //   website:"ttp://www.gotoubi.com/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/kerry.jpg",
                //   website:"https://www.kerrylogistics.com/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/ines.png",
                //   website:"http://lines.coscoshipping.com/home/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/cosco.png",
                //   website:"http://www.cosco-logistics.com.cn/"
                // },
                // {
                //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/amass.png",
                //   website:"https://www.amassfreight.com/"
                // },
            ],
            Links_arr: [
        {
          lable: "上海海关",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href.png",
          href: "http://shanghai.customs.gov.cn/",
        },
        {
          lable: "上海出入境检验检疫局",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href2.png",
          href: "http://www.shciq.gov.cn/",
        },
        {
          lable: "中国上海",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href3.png",
          href: "http://www.shanghai.gov.cn/",
        },
        {
          lable: "上海市商务委员会",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href4.png",
          href: "https://sww.sh.gov.cn/",
        },
        {
          lable: "海关总署",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href5.png",
          href: "http://www.customs.gov.cn/",
        },
        {
          lable: "AiLa",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href6.png",
          href: "https://www.ailaworld.com.cn",
        },
        {
          lable: "科越信息",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href1.png",
          href: "http://www.etongguan.com/",
        },
      ],
            imgList: [],
            showDialog: false,
            previewList: [],
            value1: 0,
        };
    },
    created() {
        this.imgList = splitArr(this.dataListWrap, 4);
    },
    mounted() {
        this.presidentList();
        this.vicePresidentList();
    },
    methods: {
        gotoWebsite(item) {
            window.open(item.link_url, "_blank");
        },
        async presidentList() {
            try {
                let params = {};
                params.source = this.PJSource;
                params.genre = 5;
                params.nopage = 1;
                let res = await this.$store.dispatch(
                    "API_index/getunitTypeList",
                    params
                );

                if (res.success) {
                    this.dataList = res.data;
                }
            } catch (e) {
                console.log(e);
            }
        },
        async vicePresidentList() {
            try {
                let params = {};
                params.source = this.PJSource;
                params.genre = 4;
                params.nopage = 1;
                let res = await this.$store.dispatch(
                    "API_index/getunitTypeList",
                    params
                );
                if (res.success) {
                    this.dataListWrap = res.data;
                    this.imgList = splitArr(this.dataListWrap, 4);
                }
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style scoped lang="less">
.box {
    display: flex;
    flex-wrap: wrap;
    padding-top: 59px;
    .left {
        width: 450px;
    }
    .right {
        width: 572px;
    }
    .bottom {
        width: 100%;
        margin-top: 30px;
    }
    .title {
        font-size: 30px;
        background-image: linear-gradient(
            0deg,
            #e7c393 0%,
            #fff5e6 99.31640625%
        );
        -webkit-background-clip: text;
        color: transparent;
    }
    .list {
        margin-top: 36px;
        .item {
            width: 180px;
            height: 60px;
            background: #ffffff;
            margin-right: 16px;
            cursor: pointer;
            position: relative;
        }
        .img {
            width: 100%;
        }
    }
}
.itemHerf {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
}
.imgPreivewDialog {
    /deep/ .el-dialog {
        border-radius: 0px;
        background: transparent;
    }
    /deep/ .el-dialog__header {
        background: transparent;
        border-radius: 0px;
        border-bottom: 0 none;
    }
}
.imgSilder {
    /deep/ .el-carousel__arrow {
        display: none;
    }
    /deep/ .el-carousel__container {
        height: 60px;
    }
    .img-wrap {
        width: 180px;
        height: 60px;
        background: #fff;
        margin-right: 16px;
        .item-img {
            width: 100%;
            height: 100%;
        }
    }
    .img-wrap:nth-child(4) {
        margin-right: 0;
    }
}
.link_box {
    display: flex;
    height: 142px;
    li {
      cursor: pointer;
      margin-right: 24px;
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
</style>
