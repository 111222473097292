<template>
  <div class="box">
    <div class="boxLeft">
      <div>
        <span class="title">{{ $t("talentZone") }}</span>
        <span class="message" :class="{ messEn: LOCALE == 'en' }">{{
          $t("checkRecruitment")
        }}</span>
      </div>
      <div class="list" v-loading="leftLoading">
        <div class="item" v-for="(item, index) in leftList" :key="index">
          <div class="item_left">
            <img :src="icons[index]" :alt="$t('siffa')" class="img" />
            <div class="adress pointer">
              <a
                :href="
                  '/positionDetail?parameter=' +
                  _encode({
                    id: item.id,
                  })
                "
              >
                {{ item.name }}
              </a>
            </div>
          </div>
          <span class="item_right date" :class="{ dateEn: LOCALE=='en' }"
            >{{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }} |
            {{ item.experience | experienceFormat(LOCALE) }}
            <span v-if="LOCALE == 'zh'">
              |{{ item.level_require | levelTypeFormat(LOCALE) }}
            </span>
          </span>
        </div>
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="boxRight clearfix">
      <div class="company fl">
        <div class="title">{{ $t("memberNews") }}</div>
        <div class="list" v-loading="rightLoading">
          <div class="item" v-for="(item, index) in rightList" :key="index">
            <div class="city">
              {{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }}
            </div>
            <div class="name">
              <span class="pointer">
                <a
                  :href="
                    '/companyDetail?parameter=' +
                    _encode({
                      company_id: item.company_id,
                    })
                  "
                >
                  {{
                    item.company_name_en
                      | priorFormat(item.company_name_zh, LOCALE)
                  }}
                </a>
              </span>
            </div>
          </div>
          <noDataImg v-if="!rightLoading && rightList.length === 0"></noDataImg>
        </div>
      </div>
      <div class="img-wrap fr">
        <div
          v-if="advertising3"
          class="bannerInner"
          :class="{
            pointer:
              advertising3.link_url &&
              advertising3.extend &&
              !advertising3.extend.is_show,
          }"
          :style="{ 'background-image': 'url(' + advertising3.img_url + ')' }"
          @click="handleDivLink(advertising3)"
        >
          <div
            class="bannerHerf"
            v-if="
              advertising3.link_url &&
              advertising3.extend &&
              !advertising3.extend.is_show
            "
          >
            <a
              :href="advertising3.link_url"
              target="_blank"
              rel="nofollow"
              v-if="
                advertising3.link_url &&
                advertising3.link_url.indexOf('http') == '0'
              "
            ></a>
            <a :href="advertising3.link_url" rel="nofollow" v-else></a>
          </div>
          <div
            v-if="advertising3.extend && advertising3.extend.is_show"
            class="bannerBtn"
            :class="'btnStyle' + advertising3.extend.buttonStyle"
            :style="{
              bottom: `${advertising3.extend.bottom}%`,
              right: `${advertising3.extend.right}%`,
              background: advertising3.extend.backgroundColor,
              borderColor: advertising3.extend.borderColor,
            }"
          >
            <a
              :style="{
                fontSize: `0.${advertising3.extend.fontSize}rem`,
                color: advertising3.extend.color,
              }"
              :href="advertising3.link_url"
              target="_blank"
              rel="nofollow"
              v-if="
                advertising3.link_url &&
                advertising3.link_url.indexOf('http') == '0'
              "
            >
              {{ advertising3.extend.content }}</a
            >
            <a
              :href="advertising3.link_url"
              rel="nofollow"
              :style="{
                fontSize: `0.${advertising3.extend.fontSize}rem`,
                color: advertising3.extend.color,
              }"
              v-else
            >
              {{ advertising3.extend.content }}</a
            >
            <!-- <el-button
              type="text"
              :style="{
                fontSize: `0.${advertising3.extend.fontSize}rem`,
                color: advertising3.extend.color,
              }"
              >{{ advertising3.extend.content }}</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schoolCooperation",
  props: {
    advertising3: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      icons: [
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon1.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon2.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon3.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon4.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon5.png",
      ],
      banner:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/activitybanner.png",
      bannerIcon:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/activity.png",
      leftList: [],
      rightList: [],
      leftLoading: false,
      rightLoading: false,
    };
  },
  mounted() {
    this.getLeftList();
    this.getRightList();
  },
  methods: {
    async getLeftList() {
      // 获取职位推荐
      this.leftLoading = true;
      let parmas = {
        start: 0,
        limit: 5,
        is_hot: 1,
        source: this.PJSource,
      };
      let data = await this.$store.dispatch(
        "API_position/getJobPositionList",
        parmas
      );
      console.log("datadata", data);
      this.leftLoading = false;
      if (data.success) {
        this.leftList = data.data;
      }
    },
    async getRightList() {
      this.rightLoading = true;
      let params = {
        source: this.PJSource,
        nopage: 0,
        start: 0,
        limit: 5,
      };
      let res = await this.$store.dispatch(
        "API_index/memberCompanysearch",
        params
      );
      this.rightLoading = false;
      if (res.success) {
        this.rightList = res.data;
      }
    },
    goCompanyDetail(item) {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: item.company_id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    handleLeft(id) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id,
          }),
        },
      });
    },
    goToMerber() {
      // this.$router.push("/memberInfo")
    },
    handleLink(link) {
      if (!link) return;
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url);
      }
    },
  },
};
</script>

<style scoped lang="less">
.box {
  margin-top: 36px;
  display: flex;
  width: 100%;
  min-height: 200px;
  .boxLeft {
    width: 365px;
    margin-right: 24px;
    background: #FCFCFC;
    padding: 5px;
    .title {
      color: #1f292e;
      font-size: 30px;
      margin-right: 12px;
    }
    .message {
      font-size: 14px;
      color: #909399;
    }
    .messEn {
      display: none;
    }
    .list {
      position: relative;
      padding-top: 4px;
      height: 180px;
      .item {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .item_left {
          display: flex;
        }
        .img {
          width: 15px;
          height: 20px;
          margin-right: 10px;
        }
        .adress {
          color: #1f292e;
          margin-right: 10px;
          max-width: 181px;
          min-width: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .date {
          color: #999;
        }
        .dateEn {
          width: 200px;
          text-align: right;
        }
      }
    }
  }
  .boxRight {
    width: 823px;
    .company {
      background: #FCFCFC;
      width: 370px;
      padding: 5px;
      height: 100%;
    }
    .title {
      color: #1f292e;
      font-size: 30px;
    }
    .tableHead {
      margin-top: 13px;
      height: 35px;
      background: #fff6f6;
      font-weight: 500;
      display: flex;
      align-items: center;
      .table1 {
        margin-left: 25px;
        width: 210px;
        color: #909399;
      }
      .table2 {
        width: 407px;
        color: #909399;
      }
      .table3 {
        color: #909399;
      }
    }
    .list {
      height: 180px;
      position: relative;
      padding-top: 4px;
      .item {
        display: flex;
        margin-top: 15px;
        .city {
          width: 90px;
          background: #ff5155;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          border-radius: 4px 4px 4px 0px;
          margin-right: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 19px;
        }
        .name {
          width: 235px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .date {
          color: #ff5155;
        }
      }
    }
    .img-wrap {
      width: 420px;
      height: 249px;
      position: relative;
      .icon {
        position: absolute;
        top: 5px;
        left: -3px;
      }
      .bannerInner {
        height: 100%;
        background-size: 100% 100%;
        position: relative;
        .bannerHerf {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          a {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
        .bannerBtn {
          position: absolute;
          padding: 0px 20px;
          border: 1px solid;
          border-radius: 4px;
          transform: translate(50%, 50%);
          &.btnStyle1 {
            border-radius: 0px;
          }
          &.btnStyle2 {
            border-radius: 4px;
          }
          &.btnStyle3 {
            border-radius: 999px;
          }
        }
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>
