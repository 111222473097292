var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.advertising1)?_c('div',{staticClass:"creditBanner"},[_c('div',{staticClass:"creditBannerImg"},[_c('div',{staticClass:"bannerInner",class:{
          pointer:
            _vm.advertising1.link_url &&
            _vm.advertising1.extend &&
            !_vm.advertising1.extend.is_show,
        },style:({ 'background-image': 'url(' + _vm.advertising1.img_url + ')' })},[(
            _vm.advertising1.link_url &&
            _vm.advertising1.extend &&
            !_vm.advertising1.extend.is_show
          )?_c('div',{staticClass:"bannerHerf"},[(
              _vm.advertising1.link_url &&
              _vm.advertising1.link_url.indexOf('http') == '0'
            )?_c('a',{attrs:{"href":_vm.advertising1.link_url,"target":"_blank","rel":"nofollow"}}):_c('a',{attrs:{"href":_vm.advertising1.link_url,"rel":"nofollow"}})]):_vm._e(),(_vm.advertising1.extend && _vm.advertising1.extend.is_show)?_c('div',{staticClass:"bannerBtn",class:'btnStyle' + _vm.advertising1.extend.buttonStyle,style:({
            bottom: `${_vm.advertising1.extend.bottom}%`,
            right: `${_vm.advertising1.extend.right}%`,
            background: _vm.advertising1.extend.backgroundColor,
            borderColor: _vm.advertising1.extend.borderColor,
          })},[(
              _vm.advertising1.link_url &&
              _vm.advertising1.link_url.indexOf('http') == '0'
            )?_c('a',{style:({
              fontSize: `0.${_vm.advertising1.extend.fontSize}rem`,
              color: _vm.advertising1.extend.color,
            }),attrs:{"href":_vm.advertising1.link_url,"target":"_blank","rel":"nofollow"}},[_vm._v(" "+_vm._s(_vm.advertising1.extend.content))]):_c('a',{style:({
              fontSize: `0.${_vm.advertising1.extend.fontSize}rem`,
              color: _vm.advertising1.extend.color,
            }),attrs:{"href":_vm.advertising1.link_url,"rel":"nofollow"}},[_vm._v(" "+_vm._s(_vm.advertising1.extend.content))])]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }