<template>
    <div class="box">
        <div class="title">{{ $t("educationalTrain") }}</div>
        <div class="more">
            <a class="pointer" href="/education_index">
                <span class="moreFont">{{ $t("companyLookMore") }}</span>
                <i class="el-icon-arrow-right" style="color: #fff"></i>
            </a>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in dataList" :key="index">
                <div class="img pointer img-wrap">
                    <al-image
                        :src="item.cover_url"
                        fit="scale-down"
                        class="item"
                    ></al-image>
                    <div class="mark">
                        <i
                            class="el-icon-video-play cursor"
                            @click="handleContent(item)"
                        ></i>
                    </div>
                </div>
                <div class="contentBox">
                    <div class="title">
                        <span>{{ item.name | fullTextFormat(15) }}</span>
                    </div>
                    <!-- <div class="content"><span class="pointer" @click="handleContent">{{ item.content | fullTextFormat(35) }}</span></div> -->
                </div>
            </div>
        </div>
        <videoPlay ref="vidoePlay"></videoPlay>
    </div>
</template>

<script>
import videoPlay from "@/baseComponents/videoPlay";
export default {
    name: "education",
    components: {
        videoPlay,
    },
    data() {
        return {
            dataList: [],
        };
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        async getDataList() {
            this.loading = true;
            let params = {
                source: this.PJSource,
                start: 0,
                limit: 4,
                home_flag: 1,
            };
            let res = await this.$store.dispatch(
                "API_company/association_video_List",
                params
            );
            this.loading = false;
            if (res.success) {
                this.dataList = res.data;
            }
        },
        handleMore() {
            // this.$router.push('/education_index')
        },
        handleContent(item) {
            if (this.IS_LOGIN) {
                this.$refs.vidoePlay.startPlay(item.aliyun_id);
            } else {
                this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
                    waitTodo: () => {
                        this.$refs.vidoePlay.startPlay(item.aliyun_id);
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
.box {
    margin-top: 36px;
    .title {
        font-size: 30px;
        background-image: linear-gradient(0deg, #E7C393 0%, #FFF5E6 99.31640625%);
        -webkit-background-clip: text;
        color: transparent;
        text-align: center;
    }
    .more {
        text-align: right;
        margin-top: 10px;
        a {
            color: #FFFFFF;
        }
    }
    .list {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        .item {
            width: 288px;
            height: 100%;
            .img {
                width: 288px;
                height: 158px;
                overflow: hidden;
                // border-radius: 10px 10px 0px 0px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .contentBox {
                padding: 14px 10px 16px;
                height: 73px;
                background: #f4faff;
                .title {
                    text-align: left;
                    font-size: 16px;
                    font-weight: bold;
                    color: #1f292e;
                    width: 256px;
                }
                .content {
                    margin-top: 6px;
                    color: #1f292e;
                    width: 256px;
                    word-wrap: break-word;
                }
            }
        }
    }
}
.pointer {
    cursor: pointer;
}
.img-wrap {
    position: relative;
    .mark {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 180px;
        line-height: 170px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-icon-video-play {
            font-size: 30px;
            color: #aaa;
        }
    }
    .mark:hover {
        background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
        color: #fff;
    }
}
</style>